$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";


.p-form-control {
  display: block;
  // width: 100%;
  padding: 10.5px;
  font-size: 1rem;
  line-height: 1.5;
  // transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-clip: padding-box;
  // border: 1px solid #ced4da;
}

.p-form-control:disabled {
  // background-color: #e9ecef;
  opacity: 1;
  cursor: not-allowed;
  // border-color: #ced4da; 
  color: #3a6890;
}

:root {
  --color: black;
  --background-color: #fff;
  --box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  --sidebar-left-margin: 20px;
  --menu-hover-color: rgb(246, 246, 246);
  --table-heading-background-color: #f2f2f2;
  --table-heading-font-weight: 600;
  --table-data-background-color: #FAFAFA;
  --profile-heading-color: rgb(153, 153, 153);


  // Landing Page colors
  --primary-color: #007BFF;
  --info-color: #909090;
  --color-black: #080808;
  --color-tertiary: #03BA0A;
  --color-white: #fff;
  --left-right-padding: 95px;
  --header-font-family: "Public Sans", "sans-serif";
  --header-font-weight: 500;
  --header-font-size: 15px;
  --body-font-family: "poppins", "sans-serif";
  --brand-name-color: #FF9500;



}

.content-wrapper {
  background-color: var(--background-color) !important;
}

.main-sidebar {
  top: 40px !important;
  left: var(--sidebar-left-margin) !important;
  border-radius: 20px;
}

// Table Styles 

.custom-table {
  border-radius: 10px;
  overflow: hidden;

}

.custom-table th {
  margin-top: 20px;
  background-color: var(--table-heading-background-color) !important;
  border: none;


}

.custom-table td {
  border: none;
  background-color: var(--table-data-background-color);
  border-top: 5px solid white;


}

.custom-table th:first-child {
  border-top-left-radius: 20px !important;
  // border-bottom-left-radius: 20px !important;
}

.custom-table th:last-child {
  border-top-right-radius: 20px !important;
  // border-bottom-right-radius: 20px !important;
}

.custom-table tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
  /* Bottom-right corner radius */
}

.custom-table tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
  /* Bottom-left corner radius */
}

.table-section {
  padding: 0 15px;
}

// Table End

.filter-section .search-input,
.input-section {
  border: none;
  outline: none;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  border-radius: 20px;
  width: 100%;

}

.action-button {
  border: none;
  outline: none;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #FFF;
  font-weight: 500;
  font-size: 10px;
}

.reg-action-button {
  border: none;
  outline: none;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #FFF;
  font-weight: 500;
  font-size: 10px;
  width: 120px;
  background-color: black;
  color: white;
  text-transform: uppercase;
}

.reg-action-button:disabled {
  background-color: rgba(0, 0, 0, 0.207);
  cursor: not-allowed;


}

.action-button:focus {
  outline: none;
}

.dropdown-button {
  border: none;
  outline: none;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #FFF;
  font-weight: 500;
  font-size: 10px;
}

.dropdown-button:focus {
  outline: none;
}

.carousel-image {
  height: 250px;
  width: 250px;
  object-fit: cover;
  aspect-ratio: 2/3;

}

.custom-card {
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  padding: 20px;

}


.brand-logo {
  width: 100px;
  box-shadow: var(--box-shadow);
}

.input-cross-icon{
  position: absolute;
  right: 12px;
  top: 12px;
}

.message-card{

}
.message-container{
  max-height: 70vh; /* Set max-height to ensure overflow */
  min-height: 50vh;
  overflow-y: auto; /* Keep overflow to auto */
  margin: 0 20px;
}
.message-container::-webkit-scrollbar{
  display: none;
}
.message-header{
  display: flex;
  justify-content: space-between;

}
.header-content{
  display: flex;
  gap: 10px;
}

.brand-detail > p {
  margin: 0;
  padding: 0;
  color: #969DAB;
}
.timestamp{
  color: #969DAB;
  font-size: 10px;
}
.brand-detail > h5 {
  margin: 0;
  padding: 0
}

.message-body{
  margin-top: 10px;
}



.message-card .brand{

}

.p-avatar.p-avatar-circle{
  border-radius: 50%;
}

.del-icon{
  font-size: 20px;
  cursor: pointer;
}
.del-icon:hover{
 color: red;
}

.custom-badge{
  background-color: #FF9500;
  color: white;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 10px;

}

.layout-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar::-webkit-scrollbar{
display: none;
}

.msg-badge{
  background-color: white;
  position: absolute;
  left: 60%;

}